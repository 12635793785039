import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <>
        <div className="subreddit_list-container">
          <div className="about-row">
            <h1>Left Leaning Subreddits</h1>
            <div className="divider-leftleaning" />
            <div className="subreddit-container">
              <span className="gray-container mx-3">
                <ul>
                  <li>r/accidentallycommunist</li>
                  <li>r/againsthatesubreddits</li>
                  <li>r/anarchafeminism</li>
                  <li>r/anarchism</li>
                  <li>r/anarchocommunism</li>
                  <li>r/anarchosyndicalism</li>
                  <li>r/anarchy101</li>
                  <li>r/ani_communism</li>
                  <li>r/antifascistsofreddit</li>
                  <li>r/antifastonetoss</li>
                  <li>r/antiwork</li>
                  <li>r/askaliberal</li>
                  <li>r/bannedfromthe_donald</li>
                  <li>r/beto2020</li>
                  <li>r/bidenbro</li>
                  <li>r/bidencoalition</li>
                  <li>r/bluemidterm2018</li>
                  <li>r/breadtube</li>
                  <li>r/centerleftpolitics</li>
                  <li>r/chapotraphouse2</li>
                  <li>r/chapotraphouse</li>
                  <li>r/chomsky</li>
                  <li>r/circlebroke2</li>
                  <li>r/circlebroke</li>
                  <li>r/communism101</li>
                  <li>r/communism</li>
                  <li>r/completeanarchy</li>
                  <li>r/dankleft</li>
                  <li>r/debateacommunist</li>
                  <li>r/debateanarchism</li>
                  <li>r/therightcantmeme</li>
                  <li>r/threearrows</li>
                  <li>r/toiletpaperusa</li>
                  <li>r/topmindsofreddit</li>
                  <li>r/tulsi</li>
                </ul>
              </span>
              <span className="gray-container mx-3">
                <ul>
                  <li>r/debatecommunism</li>
                  <li>r/democrat</li>
                  <li>r/democraticsocialism</li>
                  <li>r/demsocialists</li>
                  <li>r/elizabethwarren</li>
                  <li>r/enlightenedcentrism</li>
                  <li>r/enoughlibertarianspam</li>
                  <li>r/enoughtrumpspam</li>
                  <li>r/esist</li>
                  <li>r/fragilewhiteredditor</li>
                  <li>r/fuckthealtright</li>
                  <li>r/fullcommunism</li>
                  <li>r/genzedong</li>
                  <li>r/greenandpleasant</li>
                  <li>r/greenparty</li>
                  <li>r/impeach_trump</li>
                  <li>r/ironfrontusa</li>
                  <li>r/iww</li>
                  <li>r/joebiden</li>
                  <li>r/keep_track</li>
                  <li>r/latestagecapitalism</li>
                  <li>r/leftcommunism</li>
                  <li>r/leftwithoutedge</li>
                  <li>r/liberal</li>
                  <li>r/libertarianleft</li>
                  <li>r/libertariansocialism</li>
                  <li>r/marchagainsttrump</li>
                  <li>r/marxism_101</li>
                  <li>r/moretankiechapo</li>
                  <li>r/ndp</li>
                  <li>r/voteblue</li>
                  <li>r/wayofthebern</li>
                  <li>r/yangforpresidenthq</li>
                  <li>r/antiwork</li>
                  <li>r/murderedbyaoc</li>
                  <li>r/alltheleft</li>
                </ul>
              </span>
              <span className="gray-container mx-3">
                <ul>
                  <li>r/greenparty</li>
                  <li>r/impeach_trump</li>
                  <li>r/ironfrontusa</li>
                  <li>r/iww</li>
                  <li>r/joebiden</li>
                  <li>r/keep_track</li>
                  <li>r/latestagecapitalism</li>
                  <li>r/leftcommunism</li>
                  <li>r/leftwithoutedge</li>
                  <li>r/liberal</li>
                  <li>r/libertarianleft</li>
                  <li>r/libertariansocialism</li>
                  <li>r/marchagainsttrump</li>
                  <li>r/marxism_101</li>
                  <li>r/moretankiechapo</li>
                  <li>r/ndp</li>
                  <li>r/neoliberal</li>
                  <li>r/onguardforthee</li>
                  <li>r/ourpresident</li>
                  <li>r/pete_buttigieg</li>
                  <li>r/political_revolution</li>
                  <li>r/politicalhumor</li>
                  <li>r/pragerurine</li>
                  <li>r/presidentialracememes</li>
                  <li>r/progressive</li>
                  <li>r/russialago</li>
                  <li>r/sandersforpresident</li>
                  <li>r/selfawarewolves</li>
                  <li>r/shitliberalssay</li>
                  <li>r/shitthe_donaldSays</li>
                  <li>r/socialdemocracy</li>
                  <li>r/socialism</li>
                  <li>r/socialism_101</li>
                  <li>r/socialistra</li>
                  <li>r/stupidpol</li>
                  <li>r/the_mueller</li>
                </ul>
              </span>
            </div>
          </div>
        </div>
        <div className="subreddit_list-container2">
          <div className="about-row">
            <h1>Right Leaning Subreddits</h1>
            <div className="divider-rightleaning" />
            <div className="subreddit-container">
              <span className="gray-container mx-3">
                <ul>
                  <li>r/askaconservative</li>
                  <li>r/benshapiro</li>
                  <li>r/conservative</li>
                  <li>r/conservatives</li>
                  <li>r/conservativelounge</li>
                  <li>r/conservatives_only</li>
                  <li>r/cringeanarchy</li>
                  <li>r/draintheswamp</li>
                  <li>r/jordanpeterson</li>
                  <li>r/latestagesocialism</li>
                  <li>r/askthe_donald</li>
                  <li>r/prolife</li>
                </ul>
              </span>
              <span className="gray-container mx-3">
                <ul>
                  <li>r/louderwithcrowder</li>
                  <li>r/newpatriotism</li>
                  <li>r/neoconnwo</li>
                  <li>r/metacanada</li>
                  <li>r/paleoconservative</li>
                  <li>r/physical_removal</li>
                  <li>r/republican</li>
                  <li>r/rightwinglgbt</li>
                  <li>r/shitpoliticssays</li>
                  <li>r/shitneoconssay</li>
                  <li>r/tucker_carlson</li>
                  <li>r/conservativememes</li>
                </ul>
              </span>
              <span className="gray-container mx-3">
                <ul>
                  <li>r/the3rdposition</li>
                  <li>r/the_donald</li>
                  <li>r/thenewright</li>
                  <li>r/tuesday</li>
                  <li>r/walkaway</li>
                  <li>r/socialsusticeinaction</li>
                  <li>r/kotakuinaction</li>
                  <li>r/tumblrinaction</li>
                  <li>r/progun</li>
                  <li>r/The_Donald</li>
                  <li>r/bidenregret</li>
                  <li>r/nonewnormal</li>
                  <li>r/socialjusticeinaction</li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About;
