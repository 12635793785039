import Homepage from "./components/homepage";
import About from "./components/about.jsx";
import Subreddits from "./components/subreddit_list.jsx";
import Footer from "./components/footer";
import NotFound from "./components/404";
import DataDisplayWrapper from "./components/data_display_wrapper";
import NavComponent from "./components/navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <Router>
      <NavComponent />
      <Switch>
        <Route path="/about" component={About} />
        <Route path="/subreddit_list" component={Subreddits} />
        <Route exact path="/" component={Homepage} />
        <Route path="/submission" component={DataDisplayWrapper} />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
