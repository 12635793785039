import React, { useState, useEffect } from "react";
import ClockLoader from "react-spinners/ClockLoader";

function LoadingPage(props) {
  return (
    <>
      <div className="notfound-wrap">
        <div className="brand-text">
          <span>Processing the data from Reddit</span>
        </div>
        <span style={{ width: "75%", textAlign: "center", marginBottom: 10 }}>
          It'll take about a minute to load everything. Reddit's API limit is
          only 100 items per request with a 2 second delay before the next, and
          we're currently serving you statistics garnered from up to 50,000
          posts and comments. If you happen to have a calculator and crunch the
          numbers, that's at max 500 API calls, resulting in a whopping 1000
          seconds from delays only. But, with the help of multiprocessing we can
          significantly cut down the time to nearly 60~ seconds. So, by the time
          you're done reading this short, useless snippet of filler text, you'll
          be taken to the stat screen. Enjoy this spinning clock icon that took
          me 3 days to implement.{" "}
        </span>
        <ClockLoader size={100} />
      </div>
    </>
  );
}

export default LoadingPage;
