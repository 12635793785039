import { BrowserRouter as Router, NavLink } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";

function NavComponent() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="light">
      <Container>
        <Navbar.Brand href="#home">RedditAlgo</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* work on centering lol */}
          <Nav className="justify-content-md-center">
            <Nav.Link>
              <NavLink
                className="nav-link"
                exact
                to="/"
                activeClassName="active"
              >
                Home
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              {" "}
              <NavLink
                to="/about"
                className="nav-link"
                activeClassName="active"
              >
                About
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink
                to="/subreddit_list"
                className="nav-link"
                activeClassName="active"
              >
                Subreddit List
              </NavLink>
            </Nav.Link>
            <Nav.Link
              href="https://github.com/dean-mccoppin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <a className="nav-link">Github</a>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavComponent;
