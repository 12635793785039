import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NotFound from "./404.jsx";
import DataDisplay from "./data_display";

function DataDisplayWrapper(props) {
  const location = useLocation();

  if (location.state) {
    return <DataDisplay />;
  }
  return <NotFound />;
}

export default DataDisplayWrapper;
