import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="container-footer">
        <div>
          <span>Reddit Political Algorithm (c) 2021</span>
        </div>
      </div>
    );
  }
}

export default Footer;
