import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <>
        <div className="about-container">
          <div className="about-row">
            <h1>About</h1>
            <div className="divider-about" />
            <span>
              During one of the most politically polarizing eras in America,
              people have took to expressing their opinions on social media
              platforms, like Reddit. A common issue with user generated content
              en mass is the lack of transparency, which is important
              information especially if its accompanying contentious topics.
              RPA's objective is to find out prejudice in a thread using a
              sorting algorithm that ranks people by post activity and decides
              whether their left or right leaning. Having said that,{" "}
              <b>
                RPA simplifies political nuance into only two categories, and
                shouldn't be taken for anything more than relative statistics.
              </b>
            </span>
          </div>
          <div className="about-row">
            <h1>Privacy</h1>
            <div className="divider-privacy" />
            <span>
              All posts scraped and processed comes from Reddits API, PRAW. None
              of your submissions from sources that aren't public will be
              available via PRAW, including private subreddits or direct
              messages.  This website is not affiliated or endorsed by Reddit,
              and most likely going to end up on the third page of Google
              search.
            </span>
          </div>
          <div className="about-row">
            <h1>Subreddit List</h1>
            <div className="divider-subreddits" />
            <span>
              The algorithm uses a pre-defined subreddit list which you can
              visit here. These are used in the sorting process and help
              categorize users between left and right leaning, and are hand
              picked based on their demographics. If you have suggestions to the
              list, contact us{" "}
              <b>
                <a href="#" style={{ textDecoration: "none" }}>
                  here
                </a>
              </b>
              .
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default About;
