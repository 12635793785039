import React, { useState, useEffect } from "react";
import { Input, Tooltip, Select, LoadingOverlay } from "@mantine/core";
import { InfoCircledIcon } from "@modulz/radix-icons";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

function Homepage(props) {
  const [csrftoken, setCsrfToken] = useState(null);
  const [LinkInput, setLinkInput] = useState("");
  const [Loading, setLoading] = useState(false);
  const [InvalidInput, setInvalidInput] = useState("False");
  const [SortValue, setSortValue] = useState("top");
  const history = useHistory();

  //get token
  useEffect(() => {
    const setCookie = async () => {
      const response = await fetch(
        "https://redditalgo.herokuapp.com/set-csrf/",
        {
          credentials: "include",
        }
      );
      const data = await response.json();
      setCsrfToken(data.csrftoken);
    };
    setCookie();
  }, []);

  const updateInputValue = (evt) => {
    setLinkInput(evt.target.value);
  };

  //verify the url
  const handleLinkVerify = () => {
    const verifyLink = new RegExp(
      "reddit.com/r/[a-zA-Z0-9]+/comments/[a-zA-Z0-9]{6}/([a-zA-Z0-9]+).*$"
    );
    if (verifyLink.test(LinkInput)) {
      //attempt to access url here, if it works, proceed and send data to back end and load, if not, throw
      //error sign up
      setInvalidInput("True");
      setTimeout(() => {
        APIcall();
      }, 5000);
    } else {
      console.log("not valid");
      setInvalidInput("");
      setLoading(false);
    }
  };

  //after verification, load new page with the data
  const handleAfterVerify = () => {
    setLoading(true);
    handleLinkVerify();
  };

  //API call to check input link status code
  const APIcall = () => {
    axios
      .post(`https://redditalgo.herokuapp.com/check_url/`, LinkInput, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        withCredentials: true, // withCredentials: true to make cors requests
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status == "200") {
          history.push({
            pathname: `/submission`,
            search: "?url=" + LinkInput + "&sort=" + SortValue,
            state: { link: LinkInput, sort: SortValue, token: csrftoken },
          });
        } else {
          setInvalidInput("");
          setLoading(false);
        }
      });
  };

  return (
    <>
      <div className="black-bg-wrap">
        <LoadingOverlay
          loaderProps={{ size: "xl", color: "dark" }}
          visible={Loading}
        />
        <div className="brand-text">
          <span>Which way does Reddit lean?</span>
          <span>Find out.</span>
          <div className="container-input">
            {InvalidInput ? (
              <>
                <Input
                  placeholder="ex.
                    https://old.reddit.com/r/politics/comments/jptq5n/megat1JRpfow927XUoPtmgataMC5m5aLewzNYUP/"
                  size="md"
                  rightSection={
                    <Tooltip
                      label="old.reddit.com links work too!"
                      position="top"
                      placement="end"
                      withArrow
                    >
                      <InfoCircledIcon />
                    </Tooltip>
                  }
                  className="url-input "
                  value={LinkInput}
                  onChange={(evt) => updateInputValue(evt)}
                />
              </>
            ) : (
              <>
                <Input
                  placeholder="ex.
                    https://old.reddit.com/r/politics/comments/jptq5n/megat1JRpfow927XUoPtmgataMC5m5aLewzNYUP/"
                  size="md"
                  rightSection={
                    <Tooltip
                      label="old.reddit.com links work too!"
                      position="top"
                      placement="end"
                      withArrow
                    >
                      <InfoCircledIcon />
                    </Tooltip>
                  }
                  className="url-input"
                  value={LinkInput}
                  onChange={(evt) => updateInputValue(evt)}
                  invalid
                />
              </>
            )}
            <Select
              size="md"
              className="sort-button"
              // label="Comment Sort"
              placeholder="Comment Sort"
              data={[
                { value: "top", label: "Top" },
                { value: "controversial", label: "Controversial" },
                { value: "new", label: "New" },
              ]}
              onChange={setSortValue}
            />
          </div>
          <Button
            variant="outline-light"
            id="analyze-button"
            size="lg"
            onClick={() => handleAfterVerify()}
          >
            Analyze
          </Button>{" "}
          <p className="example-text">
            <small>
              Enter a valid Reddit thread URL paired by a comment sort to
              recieve a breakdown of which way the thread leans, including stats
              on each unique commenters political bias and most visited
              subreddits. Data is processed from a sample size of 100 users, and
              upwards of 100,000 collective posts from the selected users.
            </small>
          </p>
        </div>
      </div>
      <div className="homepage-about-container">
        <div className="row-hpabout">
          <h1 className="hp-about-title">How does RedditAlgo work?</h1>
          <div className="divider" />
          <span>
            The algorithm works by collecting a large sample size of users from
            a Reddit thread and storing up to a thousand of their most recent
            posts/comments, where it all then gets organized by subreddit. Each
            user's most active subreddits gets matched with a predetermined list
            of either left or right wing subreddits, which calculates a score
            that places a user in either of 4 categories: "Left leaning", "Right
            leaning", "Neutral", and "Undetermined". Undetermined refers to a
            user whose non-zero scoring places them equal between Left and
            Right. Neutral indicates that a user isn't majorly active in any
            known political subreddits.
          </span>
        </div>
      </div>
    </>
  );
}
export default Homepage;
