import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Paper } from "@mantine/core";
import LoadingPage from "./loading.jsx";
import axios from "axios";
import PieChart from "./pie_chart";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  LabelList,
  ResponsiveContainer,
} from "recharts";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

function DataDisplay(props) {
  const [Loading, setLoading] = useState(true);
  const [PolCounter, setPolCounter] = useState([0, 1, 2, 3]);
  const [MostFrequented, setMostFrequented] = useState([
    ["example", "example"],
  ]);
  const initialState = null;
  const [JobID, setJobID] = useState(initialState);
  const [JSONScrape, setJSONScrape] = useState(null);
  const [CommentCounter, setCommentCounter] = useState(null);
  const location = useLocation();

  //make the API call to start job
  useEffect(() => {
    APIcall();
  }, []);

  //make the API call to check backend
  useEffect(() => {
    if (JobID) {
      const interval = setInterval(() => {
        APIcallInterval();
      }, 5000);
      if (JSONScrape) {
        return () => clearInterval(interval);
      }
    }
  }, [JobID]);

  //handles initial API
  const APIcall = async () => {
    var formData = new FormData();
    formData.append("reddit_url", location.state.link);
    formData.append("sort", location.state.sort);
    try {
      const res = await axios //async, wait for promise then run next line
        .post(`https://redditalgo.herokuapp.com/reddit_data/`, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-CSRFToken": location.state.token,
          },
          withCredentials: true, // withCredentials: true to make cors requests
        });
      setJobID(res.data.job_id);
    } catch (err) {
      console.log(err);
    }
  };

  //handles interval API
  const APIcallInterval = async () => {
    //handles periodically checking if worker is done, 5 seconds check
    var formData = new FormData();
    formData.append("job_id", JobID);
    try {
      const res2 = await axios.post(
        "https://redditalgo.herokuapp.com/check_data/",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-CSRFToken": location.state.token,
          },
          withCredentials: true, // withCredentials
        }
      );
      if (res2.data.users_data) {
        setJSONScrape(res2.data.users_data);
        setPolCounter(res2.data.political_count);
        setMostFrequented(res2.data.mostactive);
        setCommentCounter(res2.data.comment_counter);
        setLoading(false);
        setJobID(null);
        window.history.replaceState(null, "");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const colors = ["#4d65ff", "#ff4d4d", "", "#a3a3a3"]; //use whatever slashed value for undetermined

  const data = [
    {
      name: "Left",
      Amount: PolCounter[1],
    },
    {
      name: "Right",
      Amount: PolCounter[0],
    },
    {
      name: "Undetermined",
      Amount: PolCounter[2],
    },
    {
      name: "Neutral",
      Amount: PolCounter[3],
    },
  ];

  if (Loading) {
    return <LoadingPage />;
  }
  return (
    <div className="data_displaywrapper">
      <div className="data-row">
        <h1>Here's the results.</h1>
        <span className="data-textcontent">
          Data from <b className="word-break ">{location.state.link}</b>, sorted
          by <b>{location.state.sort}</b>, with{" "}
          <b>{Object.keys(JSONScrape).length}</b> comments sampled. Every users
          post history from the sample pool has been aggregated from public
          records, resulting in <b>{CommentCounter}</b> posts being extracted.
        </span>
      </div>
      <div className="data-chart-row bar-chart">
        <Paper padding="md" shadow="md">
          <span className="data_title">Political Leaning</span>
          <div className="center-data">
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={data}
                margin={{ top: 20, bottom: 40 }}
                barCategoryGap={40}
                width={400}
              >
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  tick={{
                    fontSize: "auto",
                    fill: "#000000",
                    fontFamily: "Content-font",
                  }}
                  dy={20}
                />
                <Bar
                  dataKey="Amount"
                  animationBegin={500}
                  animationDuration={1500}
                >
                  <LabelList dataKey="Amount" position="top" />
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Paper>
      </div>
      <div className="data-chart-row pie-chart">
        <Paper padding="md" shadow="md">
          <span className="data_title">Shared Active Subreddits</span>
          <div className="center-data">
            <PieChart MostFrequented={MostFrequented}> </PieChart>
            <span className="data-textcontent-nm">
              Most common subreddits among the sampled users
            </span>
          </div>
        </Paper>
      </div>
      <div className="data-chart-row">
        <Paper padding="md" shadow="md">
          <span className="data_title">Raw User JSON Response</span>
          <div style={{ marginTop: 20 }}>
            <pre style={{ overflowY: "scroll", height: 400 }}>
              {JSON.stringify(JSONScrape, null, 2)}
            </pre>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default DataDisplay;
