import React, { Component } from "react";
import { useHistory, withRouter } from "react-router-dom";

class NotFound extends Component {
  handleClick = (e) => {
    this.props.history.push("/");
  };

  render() {
    return (
      <div className="notfound-wrap">
        <div className="brand-text">
          <span>Uh oh, that doesn't work.</span>
        </div>
        <span>
          This page doesn't exist. To analyze a Reddit thread again, you can{" "}
          <a href="" onClick={this.handleClick}>
            head back home.
          </a>{" "}
        </span>
      </div>
    );
  }
}

export default withRouter(NotFound);
